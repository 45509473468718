export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'current_balance',
    label: '',
    Object: 'value'
  }, {
    prop: 'withdraw_amount',
    label: '',
    Object: 'value'
  }, {
    prop: 'payment_info',
    label: '',
    Object: 'value',
    span: 24
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value'
  }, {
    prop: 'request_status',
    label: '',
    Object: 'value'
  }]
}
